import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Dialog, Typography, IconButton } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';

import * as types from '../../../actions/actionTypes';
import track from '../../../lib/track';
import { addressString } from '../../../lib/helpers';
import { isMobile } from '../../../lib/getDeviceSize';

const styles = createStyles({
  androidButton: {
    backgroundImage: 'url(/static/img/home/google_play.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 62,
    textIndent: '-99999px',
    width: 210,
  },
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 100,
  },
  cancelIcon: {
    position: 'absolute',
    right: 15,
    top: 15,
  },
  dialogWave: {
    alignItems: 'center',
    backgroundImage: 'url(/static/img/home/dialog_wave_sm.png)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    height: 170,
    justifyContent: 'center',
    width: '100%',
  },
  fullScreen: {
    backgroundColor: '#fff',
    backgroundImage:
      'url(/static/img/welcome-dialog/account_created_mobile.png)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    display: 'flex',
    flex: 1,
    margin: 0,
    maxWidth: '100%',
    minHeight: '100%',
    padding: 0,
  },
  heading: {
    color: '#ffffff',
    fontSize: 30,
    fontWeight: 'bold',
    margin: 0,
    paddingBottom: 10,
  },
  iosButton: {
    backgroundImage: 'url(/static/img/home/apple.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 69,
    textIndent: '-99999px',
    width: 210,
  },
  linkContainer: {
    padding: 0,
    margin: '0px 0 10px 0',
    backgroundColor: 'transparent',
  },
  paper: {
    backgroundColor: '#fff',
    backgroundImage:
      'url(/static/img/welcome-dialog/account_created_mobile.png)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    borderRadius: 5,
    height: 630,
    width: 370,
  },
  skipButton: {
    backgroundColor: 'transparent',
    marginTop: 35,
    width: 164,
  },
  skipText: {
    color: '#323232',
    fontSize: 14,
    letterSpacing: 1.75,
    textTransform: 'capitalize',
  },
  subText: {
    color: '#ffffff',
    fontSize: 14,
    lineHeight: '24px',
    margin: 0,
    paddingBottom: 10,
    textAlign: 'center',
  },
  whiteColor: {
    color: '#ffffff',
  },
});

export interface Props {
  classes: any;
  dispatch: any;
  fullScreen?: boolean;
  label?: string;
  mediumButton?: boolean;
  offerListing: any;
  showOfferDialog: boolean;
}
export interface State {
  tracked: boolean;
}

export class MobileDialog extends React.Component<Props, State> {
  state = {
    tracked: false,
  };

  handleClickOpen = () => {
    if (!this.state.tracked) {
      track.modalView('Mobile Dialog');
      this.setState({ tracked: true });
    }
  };

  handleClose = () => {
    this.props.dispatch({ type: types.CloseOfferDialog });
  };

  render() {
    const { classes, fullScreen, showOfferDialog, offerListing } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={showOfferDialog}
        onClose={this.handleClose}
        classes={{
          paper: isMobile() ? classes.fullScreen : classes.paper,
        }}
      >
        <div className={classes.dialogWave}>
          <Typography className={classes.heading}>Go mobile!</Typography>
          <Typography className={classes.subText}>
            Download the Torii app to start an offer
            <div className="spacer" />
            {offerListing && (
              <span>
                on <strong>{addressString(offerListing, true)}</strong>
              </span>
            )}
          </Typography>
        </div>
        <div className={classes.cancelIcon} onClick={this.handleClose}>
          <IconButton style={{ padding: 0 }}>
            <Clear className={classes.whiteColor} />
          </IconButton>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.linkContainer}
            onClick={() =>
              track.buttonClick(
                'App Store Link',
                'Home',
                'https://itunes.apple.com/us/app/torii-real-estate/id1362196303?ls=1&mt=8',
              )
            }
          >
            <a
              data-alt="iOS link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://itunes.apple.com/us/app/torii-real-estate/id1362196303?ls=1&mt=8"
              className={classes.iosButton}
            >
              Torii for iOS
            </a>
          </Button>
          <Button
            className={classes.linkContainer}
            onClick={() =>
              track.buttonClick(
                'Google Play Link',
                'Home',
                'https://play.google.com/store/apps/details?id=com.toriiapp.android',
              )
            }
          >
            <a
              data-alt="Android link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.toriiapp.android"
              className={classes.androidButton}
            >
              Torii for Android
            </a>
          </Button>
          <Button
            onClick={() => this.handleClose()}
            className={classes.skipButton}
          >
            <Typography className={classes.skipText}>MAYBE LATER</Typography>
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => ({
  offerListing: state.offer.offerListing,
  showOfferDialog: state.offer.showOfferDialog,
});

export default withStyles(styles)(connect(mapStateToProps)(MobileDialog));
